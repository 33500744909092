<template>
  <div class="content-add-provider global-content-detail-all">
    <div class="global-content-detail" >
      <div id="iHeaderDetail" ref="iHeaderDetail">
        <header-detail-component-global :sTextDetail="
          this.sTabPosition === 0
            ? 'Creación de una empresa '
            : 'Creación de una persona física '
        " sBackHref="Provider" />
        <v-breadcrumbs :items="itemsBreadcrumbs" divider="/"></v-breadcrumbs>
      </div>
      <v-container fluid>
        <!--#region SELECT TYPE ADD -->
        <v-row>
          <v-col class="content-type-add" cols="12" sm="3" md="3" lg="3" xl="3">
            <v-select v-model="sType" :items="items" label="Tipo" class="global-select"
              color="var(--primary-color-border-menu-profile)" background-color="var(--primary-color-menu)" outlined>
              <template slot="label">
                <span>Tipo<span class="important-data">*</span></span>
              </template>
            </v-select>
          </v-col>
        </v-row>
        <!--#endregion SELECT TYPE ADD -->

        <!--#region SECTION EMPRESA -->

        <v-row v-if="sType == 'Empresa'">
          <v-col cols="12">
            <div class="content-text-informacion-fiscal">
              Información de registro
            </div>
          </v-col>
          <v-col cols="12" sm="3" md="3" lg="3" xl="3">
            <v-text-field v-model="oEnterprises.sPublicName" label="Nombre público de empresa(Alias)"
              placeholder="Nombre público..." class="global-text-field" color="var(--primary-color-border-input)"
              background-color="var(--primary-color-menu)" persistent-placeholder outlined maxlength="50"
              @keyup="validateFormProvider()">
              <template slot="label">
                <span>Nombre público de empresa(Alias)
                  <span class="important-data">*</span></span>
              </template>
            </v-text-field>
          </v-col>
          <v-col cols="12" sm="3" md="3" lg="3" xl="3">
            <v-text-field v-model="oEnterprises.sEmail" label="Correo electrónico de la empresa"
              placeholder="Correo electrónico..." class="global-text-field" color="var(--primary-color-border-input)"
              background-color="var(--primary-color-menu)" persistent-placeholder outlined maxlength="70"
              @keyup="validateFormProvider()">
              <template slot="label">
                <span>Correo electrónico de la empresa
                  <span class="important-data">*</span></span>
              </template>
            </v-text-field>
          </v-col>
          <v-col cols="12" sm="6" md="6" lg="6" xl="6">
            <phone-component-global :bImportantDate="true" @updateCountryCallingCode="updateCountryCallingCode"
              :sCountryCallingCodeOrigin="oEnterprises.sCountryCallingCode"
              @updateAreaCallingCode="updateAreaCallingCode" :sAreaCallingCodeOrigin="oEnterprises.sAreaCallingCode"
              @updatePhoneNumber="updatePhoneNumber" :sPhoneNumberOrigin="oEnterprises.sPhoneNumber"
              @updatePhoneExtension="updatePhoneExtension" :sPhoneExtensionOrigin="oEnterprises.sPhoneExtension" />
          </v-col>

          <v-col cols="12" sm="3" md="3" lg="3" xl="3">
            <v-select v-model="sCountry" :items="aItemsCountry" item-text="sName" item-value="sCountryId"
              label="País de la empresa" class="global-select" color="var(--primary-color-border-input)"
              background-color="var(--primary-color-menu)" outlined @change="validateFormProvider()">
              <template slot="label">
                <span>País de la empresa<span class="important-data">*</span></span>
              </template>
            </v-select>
          </v-col>
          <v-col cols="12" sm="3" md="3" lg="3" xl="3">
            <v-select v-model="oEnterprises.sLocationStateId" :disabled="bDisabledState" :items="aItemsStates"
              item-text="sName" item-value="sStateId" label="Estado de la empresa" class="global-select"
              color="var(--primary-color-border-input)" background-color="var(--primary-color-menu)" outlined
              @change="validateFormProvider()">
              <template slot="label">
                <span>Estado de la empresa<span class="important-data">*</span></span>
              </template>
            </v-select>
          </v-col>
          <v-col cols="12" sm="3" md="3" lg="3" xl="3">
            <v-text-field v-model="oEnterprises.sLocationCityName" :disabled="!(oEnterprises.sLocationStateId !== null)"
              label="Ciudad de la empresa" placeholder="Ciudad de la empresa..." class="global-text-field"
              color="var(--primary-color-border-input)" background-color="var(--primary-color-menu)"
              persistent-placeholder outlined maxlength="40" @keyup="validateFormProvider()">
              <template slot="label">
                <span>Ciudad de la empresa<span class="important-data">*</span></span>
              </template>
            </v-text-field>
          </v-col>
          <v-col cols="12" sm="3" md="3" lg="3" xl="3">
            <v-text-field v-model="oEnterprises.sLocationZipCode" :disabled="
              !(
                oEnterprises.sLocationCityName.trim() !== '' &&
                oEnterprises.sLocationStateId !== null
              )
            " label="Código postal de la empresa" placeholder="Código postal..." class="global-text-field"
              color="var(--primary-color-border-input)" background-color="var(--primary-color-menu)"
              persistent-placeholder outlined maxlength="5" @keyup="validateFormProvider()"
              @keypress="fieldNumber($event)" @paste="noLetterZipCodeInformation()">
              <template slot="label">
                <span>Código postal de la empresa<span class="important-data">*</span></span>
              </template>
            </v-text-field>
          </v-col>

          <v-col cols="12" sm="6" md="6" lg="6" xl="6">
            <v-text-field v-model="oEnterprises.sLocationAddress" label="Dirección de la empresa"
              placeholder="Ej: Privada San Pedro..." class="global-text-field" color="var(--primary-color-border-input)"
              background-color="var(--primary-color-menu)" persistent-placeholder outlined maxlength="255"
              @keyup="validateFormProvider()">
              <template slot="label">
                <span>Dirección de la empresa<span class="important-data">*</span></span>
              </template>
            </v-text-field>
          </v-col>
          <v-col cols="12" sm="6" md="6" lg="6" xl="6">
            <v-text-field v-model="oEnterprises.sLocationOptionalAddress" label="Especificación de dirección"
              placeholder="Ej: Piso 4, Loft 1, casa color amarillo..." class="global-text-field"
              color="var(--primary-color-border-input)" background-color="var(--primary-color-menu)"
              persistent-placeholder outlined maxlength="255" @keyup="validateFormProvider()">
            </v-text-field>
          </v-col>
        </v-row>
        <v-divider v-if="sType == 'Empresa'" class="divider-global mt-9"></v-divider>

        <v-row v-if="sType == 'Empresa'">
          <v-col cols="12">
            <div class="content-text-informacion-manager">
              Información de encargado
            </div>
          </v-col>
          <v-col cols="12" sm="3" md="3" lg="3" xl="3">
            <v-text-field v-model="oEnterprises.sManagerFullName" label="Nombre del encargado de la empresa"
              placeholder="Nombre del encargado..." class="global-text-field" color="var(--primary-color-border-input)"
              background-color="var(--primary-color-menu)" persistent-placeholder outlined maxlength="50"
              @keyup="validateFormProvider()">
              <template slot="label">
                <span>Nombre del encargado de la empresa<span class="important-data">*</span></span>
              </template>
            </v-text-field>
          </v-col>
          <v-col cols="12" sm="3" md="3" lg="3" xl="3">
            <v-text-field v-model="oEnterprises.sManagerEmail" label="Correo electrónico del encargado"
              placeholder="Correo electrónico..." class="global-text-field" color="var(--primary-color-border-input)"
              background-color="var(--primary-color-menu)" persistent-placeholder outlined maxlength="70"
              @keyup="validateFormProvider()">
              <template slot="label">
                <span>Correo electrónico del encargado<span class="important-data">*</span></span>
              </template>
            </v-text-field>
          </v-col>
          <v-col cols="12" sm="6" md="6" lg="6" xl="6">
            <phone-component-global :bImportantDate="true" @updateCountryCallingCode="updateManagerCountryCallingCode"
              :sCountryCallingCodeOrigin="
                oEnterprises.sManagerCountryCallingCode
              " @updateAreaCallingCode="updateManagerAreaCallingCode"
              :sAreaCallingCodeOrigin="oEnterprises.sManagerAreaCallingCode"
              @updatePhoneNumber="updateManagerPhoneNumber" :sPhoneNumberOrigin="oEnterprises.sManagerPhoneNumber"
              @updatePhoneExtension="updateManagerPhoneExtension"
              :sPhoneExtensionOrigin="oEnterprises.sManagerPhoneExtension" />
          </v-col>
        </v-row>
        <v-divider v-if="sType == 'Empresa'" class="divider-global mt-9"></v-divider>

        <v-row v-if="sType == 'Empresa'">
          <v-col cols="12">
            <div class="content-text-informacion-fiscal">
              Información fiscal
            </div>
          </v-col>
          <v-col cols="12" sm="3" md="3" lg="3" xl="3">
            <v-text-field v-model="oEnterprises.sBussinessName" label="Nombre real de la empresa"
              placeholder="Nombre real..." class="global-text-field" color="var(--primary-color-border-input)"
              background-color="var(--primary-color-menu)" persistent-placeholder outlined maxlength="50"
              @keyup="validateFormProvider()">
              <template slot="label">
                <span>Nombre real de la empresa<span class="important-data">*</span></span>
              </template>
            </v-text-field>
          </v-col>
          <v-col cols="12" sm="3" md="3" lg="3" xl="3">
            <v-text-field v-model="oEnterprises.sTaxEmail" label="Correo electrónico de facturación"
              placeholder="Correo electrónico..." class="global-text-field" color="var(--primary-color-border-input)"
              background-color="var(--primary-color-menu)" persistent-placeholder outlined maxlength="70"
              @keyup="validateFormProvider()">
              <template slot="label">
                <span>Correo electrónico de facturación<span class="important-data">*</span></span>
              </template>
            </v-text-field>
          </v-col>
          <v-col cols="12" sm="3" md="3" lg="3" xl="3">
            <v-text-field v-model="oEnterprises.sTaxId" label="Registro federal de contribuyentes(RFC)"
              placeholder="Registro fiscal..." class="global-text-field" color="var(--primary-color-border-input)"
              background-color="var(--primary-color-menu)" persistent-placeholder outlined maxlength="13"
              @keyup="validateFormProvider()">
              <template slot="label">
                <span>Registro federal de contribuyentes(RFC)<span class="important-data">*</span></span>
              </template>
            </v-text-field>
          </v-col>

          <v-col cols="12" sm="3" md="3" lg="3" xl="3"> </v-col>

          <v-col cols="12" sm="3" md="3" lg="3" xl="3">
            <v-select v-model="sCountryTax" :items="aItemsCountry" item-text="sName" item-value="sCountryId"
              label="País(facturación)" class="global-select" color="var(--primary-color-border-input)"
              background-color="var(--primary-color-menu)" outlined @change="validateFormProvider()">
              <template slot="label">
                <span>País(facturación)<span class="important-data">*</span></span>
              </template>
            </v-select>
          </v-col>
          <v-col cols="12" sm="3" md="3" lg="3" xl="3">
            <v-select v-model="oEnterprises.sTaxLocationStateId" :disabled="bDisabledStateTax" :items="aItemsStatesTax"
              item-text="sName" item-value="sStateId" label="Estado(facturación)" class="global-select"
              color="var(--primary-color-border-input)" background-color="var(--primary-color-menu)" outlined
              @change="validateFormProvider()">
              <template slot="label">
                <span>Estado(facturación)<span class="important-data">*</span></span>
              </template>
            </v-select>
          </v-col>
          <v-col cols="12" sm="3" md="3" lg="3" xl="3">
            <v-text-field v-model="oEnterprises.sTaxLocationCityName"
              :disabled="!(oEnterprises.sTaxLocationStateId !== null)" label="Ciudad(facturación)"
              placeholder="Ciudad..." class="global-text-field" color="var(--primary-color-border-input)"
              background-color="var(--primary-color-menu)" persistent-placeholder outlined
              @keyup="validateFormProvider()" maxlength="40">
              <template slot="label">
                <span>Ciudad(facturación)<span class="important-data">*</span></span>
              </template>
            </v-text-field>
          </v-col>
          <v-col cols="12" sm="3" md="3" lg="3" xl="3">
            <v-text-field v-model="oEnterprises.sTaxLocationZipCode" :disabled="
              !(
                oEnterprises.sTaxLocationCityName.trim() !== '' &&
                oEnterprises.sTaxLocationStateId !== null
              )
            " label="Código postal(facturación)" placeholder="Código postal..." class="global-text-field"
              color="var(--primary-color-border-input)" background-color="var(--primary-color-menu)"
              persistent-placeholder outlined maxlength="5" @keyup="validateFormProvider()"
              @keypress="fieldNumber($event)" @paste="noLetterZipCodeTax()">
              <template slot="label">
                <span>Código postal(facturación)<span class="important-data">*</span></span>
              </template>
            </v-text-field>
          </v-col>

          <v-col cols="12" sm="6" md="6" lg="6" xl="6">
            <v-text-field v-model="oEnterprises.sTaxAddress" label="Dirección de facturación de la empresa"
              placeholder="Ej: Privada San Pedro..." class="global-text-field" color="var(--primary-color-border-input)"
              background-color="var(--primary-color-menu)" persistent-placeholder outlined maxlength="255"
              @keyup="validateFormProvider()">
              <template slot="label">
                <span>Dirección de facturación de la empresa<span class="important-data">*</span></span>
              </template>
            </v-text-field>
          </v-col>
          <v-col cols="12" sm="6" md="6" lg="6" xl="6">
            <v-text-field v-model="oEnterprises.sTaxOptionalAddress" label="Especificación de dirección de facturación"
              placeholder="Ej: Piso 4, Loft 1, casa color amarillo..." class="global-text-field"
              color="var(--primary-color-border-input)" background-color="var(--primary-color-menu)"
              persistent-placeholder outlined maxlength="255" @keyup="validateFormProvider()">
            </v-text-field>
          </v-col>
        </v-row>
        <v-divider v-if="sType == 'Empresa'" class="divider-global mt-9 mb-9"></v-divider>
        <!--#endregion SECTION EMPRESA -->

        <!--#region SECTION PERSON -->

        <v-row v-if="sType == 'Persona física'">
          <v-col cols="12">
            <div class="content-text-informacion-fiscal">
              Información de registro
            </div>
          </v-col>
          <v-col cols="12" sm="3" md="3" lg="3" xl="3">
            <v-text-field v-model="oPerson.sFullName" label="Nombre de proveedor" placeholder="Nombre..."
              class="global-text-field" color="var(--primary-color-border-input)"
              background-color="var(--primary-color-menu)" persistent-placeholder outlined maxlength="50"
              @keyup="validateFormPerson()">
              <template slot="label">
                <span>Nombre de proveedor
                  <span class="important-data">*</span></span>
              </template>
            </v-text-field>
          </v-col>
          <v-col cols="12" sm="3" md="3" lg="3" xl="3">
            <v-text-field v-model="oPerson.sEmail" label="Correo electrónico del proveedor"
              placeholder="Correo electrónico..." class="global-text-field" color="var(--primary-color-border-input)"
              background-color="var(--primary-color-menu)" persistent-placeholder outlined maxlength="70">
            </v-text-field>
          </v-col>
          <v-col cols="12" sm="6" md="6" lg="6" xl="6">
            <phone-component-global :bImportantDate="false" @updateCountryCallingCode="updatePersonCountryCallingCode"
              :sCountryCallingCodeOrigin="oPerson.sCountryCallingCode"
              @updateAreaCallingCode="updatePersonAreaCallingCode" :sAreaCallingCodeOrigin="oPerson.sAreaCallingCode"
              @updatePhoneNumber="updatePersonPhoneNumber" :sPhoneNumberOrigin="oPerson.sPhoneNumber"
              @updatePhoneExtension="updatePersonPhoneExtension" :sPhoneExtensionOrigin="oPerson.sPhoneExtension" />
          </v-col>

          <v-col cols="12" sm="3" md="3" lg="3" xl="3">
            <v-select v-model="sCountryPerson" :items="aItemsCountry" item-text="sName" item-value="sCountryId"
              label="País del proveedor" class="global-select" color="var(--primary-color-border-input)"
              background-color="var(--primary-color-menu)" outlined>
            </v-select>
          </v-col>
          <v-col cols="12" sm="3" md="3" lg="3" xl="3">
            <v-select v-model="oPerson.sLocationStateId" :disabled="bDisabledStatePerson" :items="aItemsStatesPerson"
              item-text="sName" item-value="sStateId" label="Estado del proveedor" class="global-select"
              color="var(--primary-color-border-input)" background-color="var(--primary-color-menu)" outlined>
            </v-select>
          </v-col>
          <v-col cols="12" sm="3" md="3" lg="3" xl="3">
            <v-text-field v-model="oPerson.sLocationCityName" :disabled="!(oPerson.sLocationStateId !== null)"
              label="Ciudad del proveedor" placeholder="Ciudad de la proveedor..." class="global-text-field"
              color="var(--primary-color-border-input)" background-color="var(--primary-color-menu)"
              persistent-placeholder outlined maxlength="40">
            </v-text-field>
          </v-col>
          <v-col cols="12" sm="3" md="3" lg="3" xl="3">
            <v-text-field v-model="oPerson.sZipCode" :disabled="
              !(
                oPerson.sLocationCityName.trim() !== '' &&
                oPerson.sLocationStateId !== null
              )
            " label="Código postal del proveedor" placeholder="Código postal..." class="global-text-field"
              color="var(--primary-color-border-input)" background-color="var(--primary-color-menu)"
              persistent-placeholder maxlength="5" outlined @keypress="fieldNumber($event)"
              @paste="noLetterZipCodePerson()">
            </v-text-field>
          </v-col>

          <v-col cols="12" sm="6" md="6" lg="6" xl="6">
            <v-text-field v-model="oPerson.sAddress" label="Dirección del proveedor"
              placeholder="Ej: Privada San Pedro..." class="global-text-field" color="var(--primary-color-border-input)"
              background-color="var(--primary-color-menu)" persistent-placeholder maxlength="255" outlined>
            </v-text-field>
          </v-col>
          <v-col cols="12" sm="6" md="6" lg="6" xl="6">
            <v-text-field v-model="oPerson.sOptionalAddress" label="Especificación de dirección"
              placeholder="Ej: Piso 4, Loft 1, casa color amarillo..." class="global-text-field"
              color="var(--primary-color-border-input)" background-color="var(--primary-color-menu)"
              persistent-placeholder outlined maxlength="255">
            </v-text-field>
          </v-col>
        </v-row>
        <v-divider v-if="sType == 'Persona física'" class="divider-global mt-9 mb-9"></v-divider>
        <!--#endregion SECTION PERSON -->

        <!--#region SECTION BUTTONS -->

        <div class="content-btns-accions">
          <div class="content-btn-second">
            <v-btn @click="close" class="global-btn-neutral"> Cancelar </v-btn>
          </div>
          <!-- <v-spacer></v-spacer> -->
          <div class="content-btn-primary">
            <v-btn class="global-btn-primary" :loading="bLoading"
              :disabled="sType == 'Empresa' ? !bAddProvider : !bAddPerson" @click="add()">
              Añadir
            </v-btn>
          </div>
        </div>
        <!--#endregion SECTION BUTTONS -->
      </v-container>
    </div>
    <!-- <footer-component-global /> -->
  </div>

</template>
<script>
export default {
  data() {
    return {
      screenWidth: 0,
      screenHeight: 0,
      heightMax: 0,
      sType: "",
      iCarousel: 1,
      bAddProvider: false,
      bAddPerson: false,
      bLoading: false,
      items: ["Empresa", "Persona física"],
      aItemsCountryPhone: [
        { value: "52", text: "+52", icono: "" },
        { value: "52", text: "+52", icono: "" },
      ],
      sCountry: null,
      sCountryTax: null,
      sCountryPerson: null,
      aItemsCountry: [],
      aItemsStates: [],
      aItemsStatesTax: [],
      aItemsStatesPerson: [],
      bDisabledState: true,
      bDisabledStateTax: true,
      bDisabledStatePerson: true,
      oEnterprises: {
        //Informacion de registro
        sPublicName: "",
        sEmail: "",
        sCountryCallingCode: "52",
        sAreaCallingCode: "",
        sPhoneNumber: "",
        sPhoneExtension: "",
        sLocationStateId: null,
        sLocationCityName: "",
        sLocationZipCode: "",
        sLocationAddress: "",
        sLocationOptionalAddress: "",

        ///Informacion de encargado
        sManagerFullName: "",
        sManagerEmail: "",
        sManagerCountryCallingCode: "52",
        sManagerAreaCallingCode: "",
        sManagerPhoneNumber: "",
        sManagerPhoneExtension: "",

        //Informacion fiscal
        sBussinessName: "",
        sTaxEmail: "",
        sTaxId: "",
        sTaxLocationStateId: null,
        sTaxLocationCityName: "",
        sTaxLocationZipCode: "",
        sTaxAddress: "",
        sTaxOptionalAddress: "",
      },
      oPerson: {
        sFullName: "",
        sEmail: "",
        sCountryCallingCode: "52",
        sAreaCallingCode: "",
        sPhoneNumber: "",
        sPhoneExtension: "",
        sLocationStateId: null,
        sLocationCityName: "",
        sZipCode: "",
        sAddress: "",
        sOptionalAddress: "",
      },
      itemsBreadcrumbs: [
        {
          text: "Proveedores",
          disabled: false,
          to: { name: "Provider" },
        },
        {
          text: "Creación de ...",
          disabled: true,
          to: { name: "" },
        },
      ],
    };
  },
  beforeMount() {
    this.setNameBreadcrumbs();
    this.getCountires();
  },
  updated() {
    this.handleResize();
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    //#region RESIZE PAGE
    matchHeight() {
      this.$nextTick((e) => {
        if (this.$refs.iHeaderDetail !== undefined) {
          this.heightMax = this.$refs.iHeaderDetail.clientHeight;
          this.heightMax = this.heightMax + 123; //123 IS NAVBAR
        }
        if (window.innerWidth > 600) {
          this.screenHeight = window.innerHeight - 100;
        } else {
          this.screenHeight = window.innerHeight - 100;
        }
      });
    },
    handleResize: function () {
      this.matchHeight();
    },
    //#endregion RESIZE PAGE
    setNameBreadcrumbs() {
      switch (this.sTabPosition) {
        case 0:
          this.itemsBreadcrumbs[1].text = "Creación de una empresa";
          this.sType = "Empresa";

          break;
        case 1:
          this.itemsBreadcrumbs[1].text = "Creación de una persona física";
          this.sType = "Persona física";
          this.validateFormPerson();

          break;

        default:
          break;
      }
    },
    fieldNumber(evt) {
      if (
        (evt.which != 8 && evt.which != 0 && evt.which < 48) ||
        evt.which > 57
      ) {
        evt.preventDefault();
      }
    },
    noLetterZipCodeInformation() {
      setTimeout(() => {
        const regexExp = /^([0-9])+$/;
        let result = regexExp.exec(this.oEnterprises.sLocationZipCode);
        if (result == null) {
          this.oEnterprises.sLocationZipCode = "";
        } else {
          this.oEnterprises.sLocationZipCode =
            this.oEnterprises.sLocationZipCode;
        }
      }, 100);
    },
    noLetterZipCodeTax() {
      setTimeout(() => {
        const regexExp = /^([0-9])+$/;
        let result = regexExp.exec(this.oEnterprises.sTaxLocationZipCode);
        if (result == null) {
          this.oEnterprises.sTaxLocationZipCode = "";
        } else {
          this.oEnterprises.sTaxLocationZipCode =
            this.oEnterprises.sTaxLocationZipCode;
        }
      }, 100);
    },
    noLetterZipCodePerson() {
      setTimeout(() => {
        const regexExp = /^([0-9])+$/;
        let result = regexExp.exec(this.oPerson.sZipCode);
        if (result == null) {
          this.oPerson.sZipCode = "";
        } else {
          this.oPerson.sZipCode = this.oPerson.sZipCode;
        }
      }, 100);
    },
    // handleResize() {
    //   if (window.innerWidth < 599) {
    //     this.screenWidth = 100;
    //   } else if (window.innerWidth > 600 && window.innerWidth < 959) {
    //     this.screenWidth = 100;
    //   } else if (window.innerWidth > 960 && window.innerWidth < 1263) {
    //     this.screenWidth = 100;
    //   } else if (window.innerWidth > 1264 && window.innerWidth < 1903) {
    //     this.screenWidth = 100;
    //   } else if (window.innerWidth > 1904) {
    //     this.screenWidth = 100;
    //   }
    // },
    emptyField() {
      this.sCountry = null;
      this.sCountryTax = null;
      this.sCountryPerson = null;
      this.bLoading = false;
      //Informacion de registro
      this.oEnterprises.sPublicName = "";
      this.oEnterprises.sEmail = "";
      this.oEnterprises.sCountryCallingCode = "52";
      this.oEnterprises.sAreaCallingCode = "";
      this.oEnterprises.sPhoneNumber = "";
      this.oEnterprises.sPhoneExtension = "";
      this.oEnterprises.sLocationStateId = null;
      this.oEnterprises.sLocationCityName = "";
      this.oEnterprises.sLocationZipCode = "";
      this.oEnterprises.sLocationAddress = "";
      this.oEnterprises.sLocationOptionalAddress = "";

      ///Informacion de encargado
      this.oEnterprises.sManagerFullName = "";
      this.oEnterprises.sManagerEmail = "";
      this.oEnterprises.sManagerCountryCallingCode = "52";
      this.oEnterprises.sManagerAreaCallingCode = "";
      this.oEnterprises.sManagerPhoneNumber = "";
      this.oEnterprises.sManagerPhoneExtension = "";

      //Informacion fiscal
      this.oEnterprises.sBussinessName = "";
      this.oEnterprises.sTaxEmail = "";
      this.oEnterprises.sTaxId = "";
      this.oEnterprises.sTaxLocationStateId = null;
      this.oEnterprises.sTaxLocationCityName = "";
      this.oEnterprises.sTaxLocationZipCode = "";
      this.oEnterprises.sTaxAddress = "";
      this.oEnterprises.sTaxOptionalAddress = "";

      this.oPerson.sFullName = "";
      this.oPerson.sEmail = "";
      this.oPerson.sCountryCallingCode = "52";
      this.oPerson.sAreaCallingCode = "";
      this.oPerson.sPhoneNumber = "";
      this.oPerson.sPhoneExtension = "";
      this.oPerson.sLocationStateId = null;
      this.oPerson.sLocationCityName = "";
      this.oPerson.sZipCode = "";
      this.oPerson.sAddress = "";
      this.oPerson.sOptionalAddress = "";

      this.bAddProvider = false;
      this.bAddPerson = false;
    },
    close() {
      this.$router.push({
        name: "Provider",
        params: {},
      });
      this.bLoading = true;

      this.emptyField();
    },
    getCountires() {
      DB.get(`${URI}/api/sp/v1/locations/countries`, {
        params: {},
        headers: {
          Authorization: "Bearer " + this.$store.state.sToken,
        },
      })
        .then((response) => {
          this.aItemsCountry = response.data.results;
          this.aItemsCountry.unshift({
            sCountryId: null,
            sName: "Seleccione un país",
            sCode: null,
          });

          this.aItemsStates.unshift({
            sStateId: null,
            sCountryId: null,
            sName: "Seleccione un estado",
            sCode: null,
          });
          this.aItemsStatesTax.unshift({
            sStateId: null,
            sCountryId: null,
            sName: "Seleccione un estado",
            sCode: null,
          });
          this.aItemsStatesPerson.unshift({
            sStateId: null,
            sCountryId: null,
            sName: "Seleccione un estado",
            sCode: null,
          });
        })
        .catch((error) => {
          this.Error(error.response.data);
        });
    },
    getState(id) {
      DB.get(`${URI}/api/sp/v1/locations/countries/${id}/states`, {
        params: {},
        headers: {
          Authorization: "Bearer " + this.$store.state.sToken,
        },
      })
        .then((response) => {
          this.oEnterprises.sLocationStateId = null;
          this.aItemsStates = response.data.results;
          this.aItemsStates.unshift({
            sStateId: null,
            sCountryId: null,
            sName: "Seleccione un estado",
            sCode: null,
          });
          this.validateFormProvider();
          this.bDisabledState = false;
        })
        .catch((error) => {
          this.Error(error.response.data);
        });
    },
    getStateTax(id) {
      DB.get(`${URI}/api/sp/v1/locations/countries/${id}/states`, {
        params: {},
        headers: {
          Authorization: "Bearer " + this.$store.state.sToken,
        },
      })
        .then((response) => {
          this.oEnterprises.sTaxLocationStateId = null;
          this.aItemsStatesTax = response.data.results;
          this.aItemsStatesTax.unshift({
            sStateId: null,
            sCountryId: null,
            sName: "Seleccione un estado",
            sCode: null,
          });
          this.validateFormProvider();
          this.bDisabledStateTax = false;
        })
        .catch((error) => {
          this.Error(error.response.data);
        });
    },
    getStatePerson(id) {
      DB.get(`${URI}/api/sp/v1/locations/countries/${id}/states`, {
        params: {},
        headers: {
          Authorization: "Bearer " + this.$store.state.sToken,
        },
      })
        .then((response) => {
          this.oPerson.sLocationStateId = null;
          this.aItemsStatesPerson = response.data.results;
          this.aItemsStatesPerson.unshift({
            sStateId: null,
            sCountryId: null,
            sName: "Seleccione un estado",
            sCode: null,
          });
          // this.validateFormPerson();
          this.bDisabledStatePerson = false;
        })
        .catch((error) => {
          this.Error(error.response.data);
        });
    },

    addProvider() {
      const payload = this.oEnterprises,
        config = {
          headers: {
            Authorization: "Bearer " + this.$store.state.sToken,
            contentType: "application/x-www-form-urlencoded;charset=utf-8",
          },
        };
      DB.post(`${URI}/api/sp/v1/vendors/enterprises`, payload, config)
        .then((response) => {
          this.Success(response.data.message);
          setTimeout(() => {
            this.$store.commit("refresher", true);
            this.$router
              .push({
                name: "Provider",
                params: {},
              })
              .catch(() => { });
          }, 2000);
        })
        .catch((error) => {
          this.bLoading = false;
          this.Error(error.response.data);
        });
    },
    addPerson() {
      // this.bLoading = true;
      const payload = this.oPerson,
        config = {
          headers: {
            Authorization: "Bearer " + this.$store.state.sToken,
            contentType: "application/x-www-form-urlencoded;charset=utf-8",
          },
        };

      DB.post(`${URI}/api/sp/v1/vendors/person`, payload, config)
        .then((response) => {
          this.Success(response.data.message);
          setTimeout(() => {
            this.$store.commit("refresher", true);
            this.$router
              .push({
                name: "Provider",
                params: {},
              })
              .catch(() => { });
          }, 2000);
        })
        .catch((error) => {
          this.bLoading = false;
          this.Error(error.response.data);
        });
    },
    add() {
      this.bLoading = true;
      this.$store
        .dispatch("getPermissionsByUserGlobal")
        .then((resp) => {
          if (this.bAdminProvider) {
            if (this.sType == "Empresa") {
              this.addProvider();
            } else {
              this.addPerson();
            }
          } else {
            this.Error(this.$store.state.oError403);
            this.bLoading = false;
            this.$router
              .push({
                name: "Provider",
                params: {},
              })
              .catch(() => { });
          }
        })
        .catch((err) => {
          this.Error(err);
        });
    },

    //UPDATE DATE PHONE ENTERPISE (ENTERPISE SECTION)
    updateCountryCallingCode(val) {
      this.oEnterprises.sCountryCallingCode = val;
      this.validateFormProvider();
    },
    updateAreaCallingCode(val) {
      this.oEnterprises.sAreaCallingCode = val;
      this.validateFormProvider();
    },
    updatePhoneNumber(val) {
      this.oEnterprises.sPhoneNumber = val;
      this.validateFormProvider();
    },
    updatePhoneExtension(val) {
      this.oEnterprises.sPhoneExtension = val;
      this.validateFormProvider();
    },

    //UPDATE DATE PHONE MANAGER (ENTERPISE SECTION)
    updateManagerCountryCallingCode(val) {
      this.oEnterprises.sManagerCountryCallingCode = val;
      this.validateFormProvider();
    },
    updateManagerAreaCallingCode(val) {
      this.oEnterprises.sManagerAreaCallingCode = val;
      this.validateFormProvider();
    },
    updateManagerPhoneNumber(val) {
      this.oEnterprises.sManagerPhoneNumber = val;
      this.validateFormProvider();
    },
    updateManagerPhoneExtension(val) {
      this.oEnterprises.sManagerPhoneExtension = val;
      this.validateFormProvider();
    },

    //UPDATE DATE PHONE PROVIDER (PERSON SECTION)
    updatePersonCountryCallingCode(val) {
      this.oPerson.sCountryCallingCode = val;
      // this.validateFormPerson();
    },
    updatePersonAreaCallingCode(val) {
      this.oPerson.sAreaCallingCode = val;
      // this.validateFormPerson();
    },
    updatePersonPhoneNumber(val) {
      this.oPerson.sPhoneNumber = val;
      // this.validateFormPerson();
    },
    updatePersonPhoneExtension(val) {
      this.oPerson.sPhoneExtension = val;
      // this.validateFormPerson();
    },

    validateFormProvider() {
      this.bAddProvider =
        this.oEnterprises.sPublicName.trim() !== "" &&
        this.oEnterprises.sEmail.trim() !== "" &&
        this.oEnterprises.sCountryCallingCode !== null &&
        this.oEnterprises.sAreaCallingCode.trim() !== "" &&
        this.oEnterprises.sPhoneNumber.trim() !== "" &&
        this.oEnterprises.sCountry !== null &&
        this.oEnterprises.sLocationStateId !== null &&
        this.oEnterprises.sLocationCityName.trim() !== "" &&
        this.oEnterprises.sLocationZipCode.trim() !== "" &&
        this.oEnterprises.sLocationAddress.trim() !== "" &&
        this.oEnterprises.sManagerFullName.trim() !== "" &&
        this.oEnterprises.sManagerEmail.trim() !== "" &&
        this.oEnterprises.sManagerCountryCallingCode !== null &&
        this.oEnterprises.sManagerAreaCallingCode.trim() !== "" &&
        this.oEnterprises.sManagerPhoneNumber.trim() !== "" &&
        this.oEnterprises.sBussinessName.trim() !== "" &&
        this.oEnterprises.sTaxEmail.trim() !== "" &&
        this.oEnterprises.sTaxId.trim() !== "" &&
        this.oEnterprises.sCountryTax !== null &&
        this.oEnterprises.sTaxLocationStateId !== null &&
        this.oEnterprises.sTaxLocationCityName.trim() !== "" &&
        this.oEnterprises.sTaxLocationZipCode.trim() !== "" &&
        this.oEnterprises.sTaxAddress.trim() !== "";
    },
    validateFormPerson() {
      this.bAddPerson = this.oPerson.sFullName.trim() !== "";
    },
  },
  computed: {
    dialogAddProvider() {
      return this.$store.state.dialogAddProvider;
    },
    sTabPosition() {
      return this.$store.state.sTabPosition;
    },
    bShowProvider() {
      return this.$store.state.bShowProvider;
    },
    bAdminProvider() {
      return this.$store.state.bAdminProvider;
    },
  },
  watch: {
    dialogAddProvider() {
      if (this.dialogAddProvider) {
      }
    },
    sCountry() {
      if (this.sCountry !== null) {
        this.getState(this.sCountry);
      } else {
        this.bDisabledState = true;
        this.oEnterprises.sLocationStateId = null;
        this.aItemsStates = [];
        this.aItemsStates.unshift({
          sStateId: null,
          sCountryId: null,
          sName: "Seleccione un estado",
          sCode: null,
        });
        this.validateFormProvider();
      }
    },
    sCountryTax() {
      if (this.sCountryTax !== null) {
        this.getStateTax(this.sCountryTax);
      } else {
        this.bDisabledStateTax = true;
        this.oEnterprises.sTaxLocationStateId = null;
        this.aItemsStatesTax = [];
        this.aItemsStatesTax.unshift({
          sStateId: null,
          sCountryId: null,
          sName: "Seleccione un estado",
          sCode: null,
        });
        this.validateFormProvider();
      }
    },
    sCountryPerson() {
      if (this.sCountryPerson !== null) {
        this.getStatePerson(this.sCountryPerson);
      } else {
        this.bDisabledStatePerson = true;
        this.oPerson.sLocationStateId = null;
        this.aItemsStatesPerson = [];
        this.aItemsStatesPerson.unshift({
          sStateId: null,
          sCountryId: null,
          sName: "Seleccione un estado",
          sCode: null,
        });
        // this.validateFormPerson();
      }
    },
    sType() {
      this.emptyField();
      this.sType === "Empresa"
        ? (this.itemsBreadcrumbs[1].text = "Creación de una empresa")
        : (this.itemsBreadcrumbs[1].text = "Creación de una persona física");

    },
  },
};
</script>
<style scoped>
.content-title-add-provider {
  width: 100%;
}

.title-add-provider {
  color: var(--primary-color-text) !important;
  font-family: "pop-SemiBold";
  font-size: 25px;
  text-align: center;
}

.content-carousel-1 {
  position: relative;
  /* height: 170px; */
  width: 100%;
  /* border: var(--primary-color-menu) dashed; */
  border-radius: 20px;
  justify-content: center;
  display: flex;
}

.content-carousel-2 {
  position: relative;
  /* height: 170px; */
  width: 100%;
  /* border: var(--primary-color-menu) dashed; */
  border-radius: 20px;
  justify-content: center;
  display: flex;
}

.content-icon-carousel {
  z-index: 9;
  position: absolute;
  display: flex;
  bottom: 10px;
  /* background-color: var(--primary-color-text-yellow); */
}

.content-icon-1 {
  cursor: pointer;
  border-radius: 100%;
  height: 10px;
  width: 10px;
  background-color: white;
}

.content-icon-2 {
  cursor: pointer;
  border-radius: 100%;
  height: 10px;
  width: 10px;
  margin-left: 10px;
  background-color: white;
}

.text-img-add {
  color: var(--primary-color-text) !important;
  font-family: "pop-Semibold";
  font-size: 9px;
  text-transform: initial;
}

.content-image {
  width: 100%;
  height: 180px !important;
  position: relative;
  border: 1px solid #70707080;
  border-radius: 20px;
}

.content-image img {
  width: 100%;
  height: auto;
}

@supports (object-fit: cover) {
  .content-image img {
    height: 100%;
    object-fit: cover;
    object-position: center center;
    border-radius: 20px;
  }
}

.button-delete-item {
  position: absolute;
  right: 5px;
  top: 5px;
  min-width: 40px !important;
  height: 40px !important;
  background-color: var(--primary-color-btn-neutral-red) !important;
  padding: 0px !important;
  border-radius: 10px;
}

.button-add-img {
  width: 100% !important;
  height: 180px !important;
  background: transparent 0% 0% no-repeat padding-box !important;
  border: 2px dashed var(--primary-color-divider);
  border-radius: 20px;
  opacity: 1;
}

.content-arrows-carousel {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.text-identification {
  margin-bottom: 0px;
  color: var(--primary-color-text);
}

.content-text-informacion-fiscal {
  letter-spacing: 4.8px;
  margin-top: 15px;
  margin-bottom: 15px;
  font-size: 14px;
  font-family: "pop-Bold";
  color: var(--primary-color-color-title-information);
}

.content-text-informacion-manager {
  letter-spacing: 4.8px;
  margin-top: 15px;
  margin-bottom: 15px;
  font-size: 14px;
  font-family: "pop-Bold";
  color: var(--primary-color-color-title-information);
}

.content-btns-accions {
  display: flex;
  width: 100% !important;
  justify-content: flex-end;
}

.content-btn-primary {
  width: 20%;
}

.content-btn-second {
  width: 20%;
  margin-right: 15px;
}

/**************************MODO RESPONSIVO ********************/
/* XS */
@media (max-width: 599px) {
  .demoColorResponsive {
    background-color: rgb(190, 136, 226);
  }

  .content-btns-accions {
    display: block;
    width: 100% !important;
  }

  .content-btn-second {
    width: 100%;
    margin-right: 0px;
    margin-bottom: 15px;
  }

  .content-btn-primary {
    width: 100%;
  }

  .text-img-add {
    color: var(--primary-color-text) !important;
    font-family: "pop-Semibold";
    font-size: 6px;
    text-transform: initial;
  }
}

/* SM */
@media (min-width: 600px) and (max-width: 959px) {
  .demoColorResponsive {
    background-color: rgb(136, 226, 151);
  }

  .text-img-add {
    color: var(--primary-color-text) !important;
    font-family: "pop-Semibold";
    font-size: 6px;
    text-transform: initial;
  }
}

/* MD */
@media (min-width: 960px) and (max-width: 1264px) {
  .demoColorResponsive {
    background-color: rgb(201, 122, 83);
  }
}

/* LG */
@media (min-width: 1264px) and (max-width: 1904px) {
  .demoColorResponsive {
    background-color: rgb(204, 198, 109);
  }
}

/* XL */
@media (min-width: 1904px) {
  .demoColorResponsive {
    background-color: rgb(113, 199, 201);
  }
}
</style>